import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';

import DashboardLayout from '../components/DashboardLayout.jsx';

import { withUser, withFilters } from 'modules/core/providers';
import {
	QUERY_MEMBER_ANALYTICS,
	QUERY_AGENT_ANALYTICS,
	QUERY_TEAM_ANALYTICS,
	QUERY_BROKERAGE_ANALYTICS
} from '../queries.js';

/** GraphQL Container **/
const withMemberAnalyticsData = graphql(QUERY_MEMBER_ANALYTICS, {
	options: ({ agentId }) => ({ variables: { agentId } }),
	skip: ({ agentId, isIndependentAgent, isLeader }) =>
		!agentId || isIndependentAgent || isLeader,
	props: ({ ownProps, data: { agent, loading } }) => {
		if (agent && agent.integrations) {
			const { twilio, webchat, email } = agent.integrations;
			const {
				respondedCount,
				notRespondedCount,
				interestedCount,
				notInterestedCount,
				needsFollowUpCount
			} = agent;

			return {
				hasPhone: twilio ? !!twilio.phoneNumber : false,
				hasEmail: email ? !!email.forwardingEmail : false,
				hasLiveChat: webchat ? !!webchat.appId : false,
				respondedCount,
				notRespondedCount,
				interestedCount,
				notInterestedCount,
				needsFollowUpCount,
				loading: ownProps.loading || loading
			};
		} else {
			return {
				loading: ownProps.loading || loading
			};
		}
	}
});

const withAgentAnalyticsData = graphql(QUERY_AGENT_ANALYTICS, {
	options: ({ agentId }) => ({ variables: { agentId } }),
	skip: ({ agentId, isIndependentAgent }) => !agentId || !isIndependentAgent,
	props: ({ ownProps, data: { agent, loading } }) => {
		if (agent) {
			const {
				respondedCount,
				notRespondedCount,
				interestedCount,
				notInterestedCount,
				needsFollowUpCount,
				voiceCallSummary
			} = agent;
			let subscriptionData = {};
			let integrationData = {};

			if (agent.subscription) {
				const {
					currentLeadCount,
					plan,
					basePlanType
				} = agent.subscription;

				subscriptionData = {
					leadCount: currentLeadCount,
					plan,
					basePlanType
				};
			}

			if (agent.integrations) {
				const { twilio, webchat, email } = agent.integrations;

				integrationData = {
					hasPhone: twilio ? !!twilio.phoneNumber : false,
					hasEmail: email ? !!email.forwardingEmail : false,
					hasLiveChat: webchat ? !!webchat.appId : false
				};
			}

			return {
				...subscriptionData,
				...integrationData,
				resellerPlanType: agent.reseller,
				respondedCount,
				notRespondedCount,
				interestedCount,
				notInterestedCount,
				needsFollowUpCount,
				voiceCallSummary,
				loading: ownProps.loading || loading
			};
		} else {
			return {
				loading: ownProps.loading || loading
			};
		}
	}
});

const withTeamAnalyticsData = graphql(QUERY_TEAM_ANALYTICS, {
	options: ({ teamId }) => ({ variables: { teamId } }),
	skip: ({ teamId, isTeamLead, isBroker }) =>
		!teamId || !isTeamLead || isBroker,
	props: ({ ownProps, data: { team, loading } }) => {
		if (team) {
			const {
				respondedCount,
				notRespondedCount,
				interestedCount,
				notInterestedCount,
				needsFollowUpCount,
				voiceCallSummary
			} = team;
			let subscriptionData = {};
			let integrationData = {};

			if (team.subscription) {
				const {
					currentLeadCount,
					plan,
					memberCount,
					basePlanType
				} = team.subscription;

				subscriptionData = {
					leadCount: currentLeadCount,
					plan,
					basePlanType,
					hasTeamMembers: memberCount > 1
				};
			}

			if (team.integrations) {
				const { twilio, webchat, email } = team.integrations;

				integrationData = {
					hasPhone: twilio ? !!twilio.phoneNumber : false,
					hasEmail: email ? !!email.forwardingEmail : false,
					hasLiveChat: webchat ? !!webchat.appId : false
				};
			}

			return {
				...subscriptionData,
				...integrationData,
				resellerPlanType: team.reseller,
				respondedCount,
				notRespondedCount,
				interestedCount,
				notInterestedCount,
				needsFollowUpCount,
				voiceCallSummary,
				loading: ownProps.loading || loading
			};
		} else {
			return {
				loading: ownProps.loading || loading
			};
		}
	}
});

const withBrokerageAnalyticsData = graphql(QUERY_BROKERAGE_ANALYTICS, {
	options: ({ brokerageId }) => ({ variables: { brokerageId } }),
	skip: ({ brokerageId, isBroker }) => !brokerageId || !isBroker,
	props: ({ ownProps, data: { brokerage, loading } }) => {
		if (brokerage) {
			const {
				respondedCount,
				notRespondedCount,
				interestedCount,
				notInterestedCount,
				needsFollowUpCount,
				voiceCallSummary
			} = brokerage;
			let subscriptionData = {};
			let integrationData = {};

			if (brokerage.subscription) {
				const {
					currentLeadCount,
					plan,
					memberCount,
					basePlanType
				} = brokerage.subscription;

				subscriptionData = {
					leadCount: currentLeadCount,
					plan,
					basePlanType,
					hasTeamMembers: memberCount > 1
				};
			}

			if (brokerage.integrations) {
				const { twilio, webchat, email } = brokerage.integrations;

				integrationData = {
					hasPhone: twilio ? !!twilio.phoneNumber : false,
					hasEmail: email ? !!email.forwardingEmail : false,
					hasLiveChat: webchat ? !!webchat.appId : false
				};
			}

			return {
				...subscriptionData,
				...integrationData,
				resellerPlanType: brokerage.reseller,
				respondedCount,
				notRespondedCount,
				interestedCount,
				notInterestedCount,
				needsFollowUpCount,
				voiceCallSummary,
				loading: ownProps.loading || loading
			};
		} else {
			return {
				loading: ownProps.loading || loading
			};
		}
	}
});

export default compose(
	withUser,
	withFilters,
	withMemberAnalyticsData,
	withAgentAnalyticsData,
	withTeamAnalyticsData,
	withBrokerageAnalyticsData
)(DashboardLayout);
