import React from 'react';

import { ChevronRight } from 'modules/blink/icons';

const SupportWidget = () => (
	<div className="support-widget">
		<div className="support-header">
			<h1 className="title">Support</h1>
			<a
				href="https://help.structurely.com/"
				target="_blank"
				rel="noopener noreferrer"
				className="link-btn"
			>
				<span className="label">Go to Support Site</span>
				<ChevronRight color="#2a354f" width="16px" height="16px" />
			</a>
		</div>
		<div className="support-card paper">
			<div className="img-wrapper">
				<img
					alt="support-icon"
					height="60px"
					src="/img/support-icon.png"
				/>
			</div>
			<div className="info-wrapper">
				<h4 className="title">Support Site</h4>
				<p className="info">
					Guides to setup your AI assistant Phone Number, integrate
					Zillow, Realtor.com and more
				</p>
			</div>
			<div className="link-wrapper">
				<a
					href="https://help.structurely.com/"
					target="_blank"
					rel="noopener noreferrer"
					className="support-link"
				>
					Go to Structurely Support Site
				</a>
			</div>
		</div>
	</div>
);

export default SupportWidget;
