import React from 'react';
import PropTypes from 'prop-types';

import { VoiceCallItem } from './VoiceCallItem.jsx';

const VoiceCallSummaryWidget = ({
	// eslint-disable-next-line react/prop-types
	totalCalls,
	totalInboundCalls,
	totalOutboundCalls,
	totalLiveTransferCalls,
	// eslint-disable-next-line react/prop-types
	callsThisInterval,
	inboundCallsThisInterval,
	outboundCallsThisInterval,
	liveTransferCallsThisInterval
}) => {
	return (
		<div className="stages-widget">
			<div className="stages-header">
				<h1 className="title">Voice Calls</h1>
			</div>
			<div className="stages-list">
				<VoiceCallItem
					label="Inbound"
					total={totalInboundCalls}
					interval={inboundCallsThisInterval}
				/>
				<VoiceCallItem
					label="Outbound"
					total={totalOutboundCalls}
					interval={outboundCallsThisInterval}
				/>
				<VoiceCallItem
					label="Live Transfer"
					total={totalLiveTransferCalls}
					interval={liveTransferCallsThisInterval}
				/>
			</div>
		</div>
	);
};

VoiceCallSummaryWidget.propTypes = {
	totalInboundCalls: PropTypes.number.isRequired,
	totalOutboundCalls: PropTypes.number.isRequired,
	totalLiveTransferCalls: PropTypes.number.isRequired,
	inboundCallsThisInterval: PropTypes.number.isRequired,
	outboundCallsThisInterval: PropTypes.number.isRequired,
	liveTransferCallsThisInterval: PropTypes.number.isRequired
};

export default VoiceCallSummaryWidget;
