import PropTypes from 'prop-types';

export const VoiceCallItem = ({ label, total }) => {
	return (
		<div className="stage-item paper">
			<h4 className="info-title">{label}</h4>
			<div className="info">
				<span className="info-label">
					{total} call{total === 1 ? '' : 's'}
				</span>
			</div>
		</div>
	);
};

VoiceCallItem.propTypes = {
	label: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired
};
