import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { FiltersContext } from '../contexts';

function FiltersProvider(props) {
	const { children } = props;

	const [filters, updateFilters] = useState({
		stageFilters: [],
		typeFilters: [{ value: 'archived', status: false }],
		sourceFilters: [],
		agentFilter: null,
		teamFilter: null
	});

	let filterLabels = {
		assigned: {
			true: 'Assigned',
			false: 'New'
		},
		archived: {
			true: 'Closed',
			false: 'Open'
		},
		muted: {
			true: 'Muted',
			false: 'Unmuted'
		},
		agentEngaged: {
			true: 'Engaged',
			false: 'Not Engaged'
		},
		web: 'Live chat',
		api: 'Form',
		twilio: 'SMS Text',
		mailgun: 'Email',
		responded: 'Responded',
		not_responded: 'Unresponsive',
		interested: 'Interested',
		not_interested: 'Not Interested',
		needs_follow_up: 'Agent Follow-Up'
	};

	const clearFilters = () => {
		updateFilters({
			stageFilters: [],
			typeFilters: [],
			sourceFilters: [],
			agentFilter: null,
			teamFilter: null
		});
	};

	const updateStageFilters = value => {
		if (!value) return;

		let stageFilters = filters['stageFilters'];
		let newStageFilters = [];
		if (Array.isArray(value)) {
			newStageFilters = value;
		} else if (stageFilters.includes(value)) {
			newStageFilters = [];
		} else {
			newStageFilters = [value];
		}

		updateFilters({
			...filters,
			stageFilters: newStageFilters
		});
	};

	const updateTypeFilters = (value, status) => {
		if (!value) return;

		let typeFilters = filters['typeFilters'];
		let newTypeFilters = [];
		if (Array.isArray(value)) {
			newTypeFilters = value;
		} else if (status === undefined) {
			newTypeFilters = typeFilters.filter(
				filter => filter.value !== value
			);
		} else if (typeFilters.find(filter => filter.value === value)) {
			newTypeFilters = typeFilters.map(filter => {
				if (filter.value === value) {
					return {
						value,
						status
					};
				} else {
					return filter;
				}
			});
		} else {
			newTypeFilters = [
				...typeFilters,
				{
					value,
					status
				}
			];
		}

		updateFilters({
			...filters,
			typeFilters: newTypeFilters
		});
	};

	const updateSourceFilters = value => {
		if (!value) return;

		let sourceFilters = filters['sourceFilters'];
		let newSourceFilters = [];
		if (Array.isArray(value)) {
			newSourceFilters = value;
		} else if (sourceFilters.includes(value)) {
			newSourceFilters = sourceFilters.filter(filter => filter !== value);
		} else {
			newSourceFilters = [...sourceFilters, value];
		}

		updateFilters({
			...filters,
			sourceFilters: newSourceFilters
		});
	};

	const updateAgentFilter = (id, name) => {
		if (!id) {
			updateFilters({
				...filters,
				agentFilter: null
			});
		} else {
			updateFilters({
				...filters,
				agentFilter: {
					id,
					name
				},
				teamFilter: null
			});
		}
	};

	const updateTeamFilter = (id, name) => {
		if (!id) {
			updateFilters({
				...filters,
				teamFilter: null
			});
		} else {
			updateFilters({
				...filters,
				teamFilter: {
					id,
					name
				},
				agentFilter: null
			});
		}
	};

	const getFilterLabel = (value, status) => {
		let filter = filterLabels[value];

		if (!filter) return '';
		if (typeof filter === 'string') {
			return filter;
		} else if (typeof filter === 'object' && filter[status]) {
			return filter[status];
		} else {
			return '';
		}
	};

	return (
		<FiltersContext.Provider
			value={{
				filters: {
					stageFilters: filters['stageFilters'],
					sourceFilters: filters['sourceFilters'],
					typeFilters: filters['typeFilters'],
					agentFilter: filters['agentFilter'],
					teamFilter: filters['teamFilter']
				},
				filterActions: {
					clearFilters,
					getFilterLabel,
					updateStageFilters,
					updateTypeFilters,
					updateSourceFilters,
					updateAgentFilter,
					updateTeamFilter
				}
			}}
		>
			{children}
		</FiltersContext.Provider>
	);
}

FiltersProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]).isRequired
};

export default withRouter(FiltersProvider);
