import { gql } from '@apollo/client';

import {
	FRAGMENT_TWILIO_METADATA,
	FRAGMENT_STRUCTURELY_ROI,
	FRAGMENT_STRIPE_SUBSCRIPTION_TYPE,
	FRAGMENT_RESELLER_SUBSCRIPTION_TYPE,
	FRAGMENT_VOICE_CALL_SUMMARY
} from 'modules/graphql/fragments';

const QUERY_MEMBER_ANALYTICS = gql`
	query QueryMemberAnalytics($agentId: ID!) {
		agent(id: $agentId) {
			id
			integrations {
				email {
					forwardingEmail
				}
				webchat {
					appId
				}
				twilio {
					...TwilioIntegration
				}
			}
			respondedCount: totalCustomersCount(stages: ["responded"])
			notRespondedCount: totalCustomersCount(stages: ["not_responded"])
			interestedCount: totalCustomersCount(stages: ["interested"])
			notInterestedCount: totalCustomersCount(stages: ["not_interested"])
			needsFollowUpCount: totalCustomersCount(stages: ["needs_follow_up"])
		}
	}
	${FRAGMENT_TWILIO_METADATA}
`;

const QUERY_AGENT_ANALYTICS = gql`
	query QueryAgentAnalytics($agentId: ID!) {
		agent(id: $agentId) {
			id
			integrations {
				email {
					forwardingEmail
				}
				webchat {
					appId
				}
				twilio {
					...TwilioIntegration
				}
			}
			subscription {
				...StripeSubscription
			}
			reseller {
				...ResellerSubscription
			}
			respondedCount: totalCustomersCount(stages: ["responded"])
			notRespondedCount: totalCustomersCount(stages: ["not_responded"])
			interestedCount: totalCustomersCount(stages: ["interested"])
			notInterestedCount: totalCustomersCount(stages: ["not_interested"])
			needsFollowUpCount: totalCustomersCount(stages: ["needs_follow_up"])
			voiceCallSummary {
				...VoiceCallSummary
			}
		}
	}
	${FRAGMENT_VOICE_CALL_SUMMARY}
	${FRAGMENT_TWILIO_METADATA}
	${FRAGMENT_STRIPE_SUBSCRIPTION_TYPE}
	${FRAGMENT_RESELLER_SUBSCRIPTION_TYPE}
`;

const QUERY_TEAM_ANALYTICS = gql`
	query QueryTeamAnalytics($teamId: ID!) {
		team(id: $teamId) {
			id
			integrations {
				email {
					forwardingEmail
				}
				webchat {
					appId
				}
				twilio {
					...TwilioIntegration
				}
			}
			subscription {
				memberCount
				...StripeSubscription
			}
			reseller {
				...ResellerSubscription
			}
			respondedCount: totalCustomersCount(stages: ["responded"])
			notRespondedCount: totalCustomersCount(stages: ["not_responded"])
			interestedCount: totalCustomersCount(stages: ["interested"])
			notInterestedCount: totalCustomersCount(stages: ["not_interested"])
			needsFollowUpCount: totalCustomersCount(stages: ["needs_follow_up"])
			voiceCallSummary {
				...VoiceCallSummary
			}
		}
	}
	${FRAGMENT_VOICE_CALL_SUMMARY}
	${FRAGMENT_TWILIO_METADATA}
	${FRAGMENT_STRIPE_SUBSCRIPTION_TYPE}
	${FRAGMENT_RESELLER_SUBSCRIPTION_TYPE}
`;

const QUERY_BROKERAGE_ANALYTICS = gql`
	query QueryBrokerageAnalytics($brokerageId: ID!) {
		brokerage(id: $brokerageId) {
			id
			integrations {
				email {
					forwardingEmail
				}
				webchat {
					appId
				}
				twilio {
					...TwilioIntegration
				}
			}
			subscription {
				memberCount
				...StripeSubscription
			}
			reseller {
				...ResellerSubscription
			}
			respondedCount: totalCustomersCount(stages: ["responded"])
			notRespondedCount: totalCustomersCount(stages: ["not_responded"])
			interestedCount: totalCustomersCount(stages: ["interested"])
			notInterestedCount: totalCustomersCount(stages: ["not_interested"])
			needsFollowUpCount: totalCustomersCount(stages: ["needs_follow_up"])
			voiceCallSummary {
				...VoiceCallSummary
			}
		}
	}
	${FRAGMENT_VOICE_CALL_SUMMARY}
	${FRAGMENT_TWILIO_METADATA}
	${FRAGMENT_STRIPE_SUBSCRIPTION_TYPE}
	${FRAGMENT_RESELLER_SUBSCRIPTION_TYPE}
`;

const QUERY_AGENT_ROI = gql`
	query QueryAgentRoi(
		$id: ID!
		$appointmentSetToCloseRatio: Float
		$averageSellingPrice: Float
		$averageCommissionRate: Float
	) {
		agent(id: $id) {
			id
			structurelyRoi(
				appointmentSetToCloseRatio: $appointmentSetToCloseRatio
				averageSellingPrice: $averageSellingPrice
				averageCommissionRate: $averageCommissionRate
			) {
				...StructurelyRoi
			}
		}
	}
	${FRAGMENT_STRUCTURELY_ROI}
`;

const QUERY_TEAM_ROI = gql`
	query QueryTeamRoi(
		$id: ID!
		$appointmentSetToCloseRatio: Float
		$averageSellingPrice: Float
		$averageCommissionRate: Float
	) {
		team(id: $id) {
			id
			structurelyRoi(
				appointmentSetToCloseRatio: $appointmentSetToCloseRatio
				averageSellingPrice: $averageSellingPrice
				averageCommissionRate: $averageCommissionRate
			) {
				...StructurelyRoi
			}
		}
	}
	${FRAGMENT_STRUCTURELY_ROI}
`;

const QUERY_BROKERAGE_ROI = gql`
	query QueryBrokerageRoi(
		$id: ID!
		$appointmentSetToCloseRatio: Float
		$averageSellingPrice: Float
		$averageCommissionRate: Float
	) {
		brokerage(id: $id) {
			id
			structurelyRoi(
				appointmentSetToCloseRatio: $appointmentSetToCloseRatio
				averageSellingPrice: $averageSellingPrice
				averageCommissionRate: $averageCommissionRate
			) {
				...StructurelyRoi
			}
		}
	}
	${FRAGMENT_STRUCTURELY_ROI}
`;

export {
	QUERY_MEMBER_ANALYTICS,
	QUERY_AGENT_ANALYTICS,
	QUERY_TEAM_ANALYTICS,
	QUERY_BROKERAGE_ANALYTICS,
	QUERY_AGENT_ROI,
	QUERY_TEAM_ROI,
	QUERY_BROKERAGE_ROI
};
