import React from 'react';
import PropTypes from 'prop-types';

import '../styles.scss';
import { Spinner } from 'modules/core/components';
import LeadProgress from './LeadProgress.jsx';
import RoiWidget from './RoiWidget.jsx';
import StagesWidget from './StagesWidget.jsx';
import SetupProgress from './SetupProgress.jsx';
import SupportWidget from './SupportWidget.jsx';
import VoiceCallSummaryWidget from './VoiceCallSummaryWidget';

const DashboardLayout = ({
	leadCount,
	user,
	plan,
	basePlanType,
	resellerPlanType,
	hasPhone,
	hasEmail,
	hasLiveChat,
	hasTeamMembers,
	isLeader,
	isIndependentAgent,
	respondedCount,
	notRespondedCount,
	interestedCount,
	notInterestedCount,
	needsFollowUpCount,
	updateStageFilters,
	voiceCallSummary,
	loading
}) => {
	const getLeadLimit = () => {
		switch (plan) {
			case 'starter':
				return 50;
			case 'growth':
				return 125;
			case 'build':
				return 225;
			case 'franchise':
				return 500;
			case 'franchise_plus':
				return 1000;
			case 'franchise_plus_dbl':
				return 2000;
			case 'entry':
				return 100;
			case 'explore':
				return 200;
			case 'elevate':
				return 500;
			case 'established':
				return 1000;
			case 'beyond':
				return 2500;
			case 'edge':
				return 5000;
			default:
				return 0;
		}
	};

	const renderLeadProgress = () => {
		let hasOldPlan = ['individual', 'team', 'brokerage'].includes(plan);
		let isMember = !isLeader && !isIndependentAgent;

		// Modern billing plans use metadata from the backend
		if (basePlanType?.leadsPerInterval && basePlanType?.billingInterval) {
			const interval = basePlanType.billingInterval.replace(/ly$/, '');
			return (
				<LeadProgress
					leadCount={leadCount}
					leadLimit={basePlanType.leadsPerInterval}
					billingInterval={interval}
				/>
			);
		}

		if (
			resellerPlanType?.leadsPerInterval &&
			resellerPlanType?.billingInterval
		) {
			const interval = resellerPlanType.billingInterval.replace(
				/ly$/,
				''
			);
			return (
				<LeadProgress
					leadCount={resellerPlanType.currentLeadCount}
					leadLimit={resellerPlanType.leadsPerInterval}
					billingInterval={interval}
				/>
			);
		}

		// Legacy plan information and workflows
		if (!plan || hasOldPlan || isMember || plan === 'custom') {
			return null;
		}

		// Legacy plan information and workflows
		return (
			<LeadProgress leadCount={leadCount} leadLimit={getLeadLimit()} />
		);
	};

	if (loading) {
		return (
			<div className="layout-spinner-wrapper">
				<Spinner lg />
			</div>
		);
	}

	return (
		<div className="layout dashboard-layout">
			<div className="content">
				{renderLeadProgress()}
				{user.isBroker && user.brokerageId && <RoiWidget user={user} />}
				<StagesWidget
					respondedCount={respondedCount}
					notRespondedCount={notRespondedCount}
					interestedCount={interestedCount}
					notInterestedCount={notInterestedCount}
					needsFollowUpCount={needsFollowUpCount}
					updateStageFilters={updateStageFilters}
				/>
				{!!voiceCallSummary && (
					<VoiceCallSummaryWidget {...voiceCallSummary} />
				)}
				<SetupProgress
					hasPhone={hasPhone}
					hasEmail={hasEmail}
					hasLiveChat={hasLiveChat}
					hasTeamMembers={hasTeamMembers}
					isLeader={isLeader}
					plan={plan}
					basePlanType={basePlanType}
				/>
			</div>
			<div className="side-content">
				<SupportWidget />
			</div>
		</div>
	);
};

DashboardLayout.propTypes = {
	loading: PropTypes.bool,
	user: PropTypes.object,
	leadCount: PropTypes.number,
	plan: PropTypes.string,
	basePlanType: PropTypes.object,
	resellerPlanType: PropTypes.object,
	hasPhone: PropTypes.bool,
	hasEmail: PropTypes.bool,
	hasLiveChat: PropTypes.bool,
	hasTeamMembers: PropTypes.bool,
	isLeader: PropTypes.bool,
	isIndependentAgent: PropTypes.bool,
	respondedCount: PropTypes.number,
	notRespondedCount: PropTypes.number,
	interestedCount: PropTypes.number,
	notInterestedCount: PropTypes.number,
	needsFollowUpCount: PropTypes.number,
	voiceCallSummary: PropTypes.object,
	updateStageFilters: PropTypes.func.isRequired
};

DashboardLayout.defaultProps = {
	loading: false,
	user: null,
	leadCount: 0,
	plan: '',
	basePlanType: undefined,
	resellerPlanType: undefined,
	hasPhone: false,
	hasEmail: false,
	hasLiveChat: false,
	hasTeamMembers: false,
	isLeader: false,
	isIndependentAgent: false,
	respondedCount: 0,
	notRespondedCount: 0,
	interestedCount: 0,
	notInterestedCount: 0,
	needsFollowUpCount: 0,
	voiceCallSummary: undefined
};

export default DashboardLayout;
